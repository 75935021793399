<template>
  <!--  -->
  <div class="m-endorder">
    <img :src="endorder" alt="" />
    <p>{{ desc }}</p>
  </div>
</template>
<script>
import endorder from "assets/images/payhelp/endorder.png";
import Utils from "utils/util";

export default {
  /**
   * 初始化的函数方法
   * @returns {void}
   */
  data() {
    return { endorder: endorder, desc: "" };
  },
  methods: {},
  /**
   * 这个是初始化执行的函数
   * @returns {void}
   */
  mounted() {
    // console.log(Utils.getUrlData("desc"));
    this.desc = decodeURIComponent(Utils.getUrlData("desc"));
  }
};
</script>
<style scoped>
.m-endorder {
  width: 100vw;
  height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.13rem;
  line-height: 0.18rem;
  color: #9b9fa6;
}
</style>
